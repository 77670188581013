export class EnvService {

     // APIs
     public server = '';
     public identity = '';
     public serverNotifications = '';
     public endpointAMD = '';
     public endpointAS = '';
     public endpointPS = '';
     public signalRUrl = '';
     
     // Front-End
     public frontEnd = '';
     public client_id = '';
     public dev_mode = false;
     public isTrNotificationsAvailable = false;
     public showTabsTree = false;
     public logo = '';
     public specialProjectsArray = [];

    constructor() {
    }

  }
