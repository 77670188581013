/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllPunchesGetIdByCriteriaBody } from '../model/allPunchesGetIdByCriteriaBody';
import { AllPunchesGetIdByCriteriaBody1 } from '../model/allPunchesGetIdByCriteriaBody1';
import { AllPunchesGetIdByCriteriaBody2 } from '../model/allPunchesGetIdByCriteriaBody2';
import { AllPunchesGetIdByCriteriaBody3 } from '../model/allPunchesGetIdByCriteriaBody3';
import { AllPunchesPagedBody } from '../model/allPunchesPagedBody';
import { AllPunchesPagedBody1 } from '../model/allPunchesPagedBody1';
import { AllPunchesPagedBody2 } from '../model/allPunchesPagedBody2';
import { AllPunchesPagedBody3 } from '../model/allPunchesPagedBody3';
import { ExportViewModel } from '../model/exportViewModel';
import { IActionResult } from '../model/iActionResult';
import { ImportViewModel } from '../model/importViewModel';
import { NewPagedBody } from '../model/newPagedBody';
import { NewPagedBody1 } from '../model/newPagedBody1';
import { NewPagedBody2 } from '../model/newPagedBody2';
import { NewPagedBody3 } from '../model/newPagedBody3';
import { PagedElementIdBody } from '../model/pagedElementIdBody';
import { PagedElementIdBody1 } from '../model/pagedElementIdBody1';
import { PagedElementIdBody2 } from '../model/pagedElementIdBody2';
import { PagedElementIdBody3 } from '../model/pagedElementIdBody3';
import { PagedElementIdBody4 } from '../model/pagedElementIdBody4';
import { PagedElementIdBody5 } from '../model/pagedElementIdBody5';
import { PagedElementIdBody6 } from '../model/pagedElementIdBody6';
import { PagedElementIdBody7 } from '../model/pagedElementIdBody7';
import { PagedOBSOLETEDBody } from '../model/pagedOBSOLETEDBody';
import { PagedOBSOLETEDBody1 } from '../model/pagedOBSOLETEDBody1';
import { PagedOBSOLETEDBody2 } from '../model/pagedOBSOLETEDBody2';
import { PagedOBSOLETEDBody3 } from '../model/pagedOBSOLETEDBody3';
import { PunchAvailableColumnViewModel } from '../model/punchAvailableColumnViewModel';
import { PunchCreateMediaBody } from '../model/punchCreateMediaBody';
import { PunchCreateMediaBody1 } from '../model/punchCreateMediaBody1';
import { PunchCreateMediaBody2 } from '../model/punchCreateMediaBody2';
import { PunchCreateMediaBody3 } from '../model/punchCreateMediaBody3';
import { PunchDetailViewModel } from '../model/punchDetailViewModel';
import { PunchExportPunchesBody } from '../model/punchExportPunchesBody';
import { PunchExportPunchesBody1 } from '../model/punchExportPunchesBody1';
import { PunchExportPunchesBody2 } from '../model/punchExportPunchesBody2';
import { PunchExportPunchesBody3 } from '../model/punchExportPunchesBody3';
import { PunchMediaViewModel } from '../model/punchMediaViewModel';
import { PunchPostPunchAndReturnIdBody } from '../model/punchPostPunchAndReturnIdBody';
import { PunchPostPunchAndReturnIdBody1 } from '../model/punchPostPunchAndReturnIdBody1';
import { PunchPostPunchAndReturnIdBody2 } from '../model/punchPostPunchAndReturnIdBody2';
import { PunchPostPunchAndReturnIdBody3 } from '../model/punchPostPunchAndReturnIdBody3';
import { PunchPutPunchAndReturnIdBody } from '../model/punchPutPunchAndReturnIdBody';
import { PunchPutPunchAndReturnIdBody1 } from '../model/punchPutPunchAndReturnIdBody1';
import { PunchPutPunchAndReturnIdBody2 } from '../model/punchPutPunchAndReturnIdBody2';
import { PunchPutPunchAndReturnIdBody3 } from '../model/punchPutPunchAndReturnIdBody3';
import { PunchRejectBody } from '../model/punchRejectBody';
import { PunchRejectBody1 } from '../model/punchRejectBody1';
import { PunchRejectBody2 } from '../model/punchRejectBody2';
import { PunchRejectBody3 } from '../model/punchRejectBody3';
import { PunchRejectByIdsBody } from '../model/punchRejectByIdsBody';
import { PunchRejectByIdsBody1 } from '../model/punchRejectByIdsBody1';
import { PunchRejectByIdsBody2 } from '../model/punchRejectByIdsBody2';
import { PunchRejectByIdsBody3 } from '../model/punchRejectByIdsBody3';
import { PunchRemarkBody } from '../model/punchRemarkBody';
import { PunchRemarkBody1 } from '../model/punchRemarkBody1';
import { PunchRemarkBody2 } from '../model/punchRemarkBody2';
import { PunchRemarkBody3 } from '../model/punchRemarkBody3';
import { PunchRemarkBody4 } from '../model/punchRemarkBody4';
import { PunchRemarkBody5 } from '../model/punchRemarkBody5';
import { PunchRemarkBody6 } from '../model/punchRemarkBody6';
import { PunchRemarkBody7 } from '../model/punchRemarkBody7';
import { PunchSignBody } from '../model/punchSignBody';
import { PunchSignBody1 } from '../model/punchSignBody1';
import { PunchSignBody2 } from '../model/punchSignBody2';
import { PunchSignBody3 } from '../model/punchSignBody3';
import { PunchSignByIdsBody } from '../model/punchSignByIdsBody';
import { PunchSignByIdsBody1 } from '../model/punchSignByIdsBody1';
import { PunchSignByIdsBody2 } from '../model/punchSignByIdsBody2';
import { PunchSignByIdsBody3 } from '../model/punchSignByIdsBody3';
import { PunchSignPunchesPreviewBody } from '../model/punchSignPunchesPreviewBody';
import { PunchSignPunchesPreviewBody1 } from '../model/punchSignPunchesPreviewBody1';
import { PunchSignPunchesPreviewBody2 } from '../model/punchSignPunchesPreviewBody2';
import { PunchSignPunchesPreviewBody3 } from '../model/punchSignPunchesPreviewBody3';
import { PunchSignPunchesPreviewExcelBody } from '../model/punchSignPunchesPreviewExcelBody';
import { PunchSignPunchesPreviewExcelBody1 } from '../model/punchSignPunchesPreviewExcelBody1';
import { PunchSignPunchesPreviewExcelBody2 } from '../model/punchSignPunchesPreviewExcelBody2';
import { PunchSignPunchesPreviewExcelBody3 } from '../model/punchSignPunchesPreviewExcelBody3';
import { PunchTransferPreviewBody } from '../model/punchTransferPreviewBody';
import { PunchTransferPreviewBody1 } from '../model/punchTransferPreviewBody1';
import { PunchTransferPreviewBody2 } from '../model/punchTransferPreviewBody2';
import { PunchTransferPreviewBody3 } from '../model/punchTransferPreviewBody3';
import { PunchUpdatePreviewViewModel } from '../model/punchUpdatePreviewViewModel';
import { PunchViewModel } from '../model/punchViewModel';
import { PunchViewModelDataSourceResult } from '../model/punchViewModelDataSourceResult';
import { ResultDetailsTemplateViewModel } from '../model/resultDetailsTemplateViewModel';
import { TransferSaveConfigBody } from '../model/transferSaveConfigBody';
import { TransferSaveConfigBody1 } from '../model/transferSaveConfigBody1';
import { TransferSaveConfigBody2 } from '../model/transferSaveConfigBody2';
import { TransferSaveConfigBody3 } from '../model/transferSaveConfigBody3';
import { V1PunchBody } from '../model/v1PunchBody';
import { V1PunchBody1 } from '../model/v1PunchBody1';
import { V1PunchBody2 } from '../model/v1PunchBody2';
import { V1PunchBody3 } from '../model/v1PunchBody3';
import { V1PunchBody4 } from '../model/v1PunchBody4';
import { V1PunchBody5 } from '../model/v1PunchBody5';
import { V1PunchBody6 } from '../model/v1PunchBody6';
import { V1PunchBody7 } from '../model/v1PunchBody7';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PunchService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param punchId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignTo(punchId: number, userId: string, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public assignTo(punchId: number, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public assignTo(punchId: number, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public assignTo(punchId: number, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (punchId === null || punchId === undefined) {
            throw new Error('Required parameter punchId was null or undefined when calling assignTo.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling assignTo.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch/${encodeURIComponent(String(punchId))}/AssignTo/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param punchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTemporalPunch(punchId?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteTemporalPunch(punchId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteTemporalPunch(punchId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteTemporalPunch(punchId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (punchId !== undefined && punchId !== null) {
            queryParameters = queryParameters.set('punchId', <any>punchId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/Punch/deleteTemporalPunch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletepunch(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deletepunch(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deletepunch(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deletepunch(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deletepunch.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/Punch/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadPunchAttachments(body?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public downloadPunchAttachments(body?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public downloadPunchAttachments(body?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public downloadPunchAttachments(body?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/v1/Punch/DownloadPunchAttachments`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editRemarkPunchById(body?: PunchRemarkBody, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public editRemarkPunchById(body?: PunchRemarkBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public editRemarkPunchById(body?: PunchRemarkBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public editRemarkPunchById(body?: PunchRemarkBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('put',`${this.basePath}/v1/Punch/Remark`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param exportPath Absolute path to export files. Example: C:\\Test\\Folder\\Files
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportAllAttachments(body?: Array<number>, exportPath?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public exportAllAttachments(body?: Array<number>, exportPath?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public exportAllAttachments(body?: Array<number>, exportPath?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public exportAllAttachments(body?: Array<number>, exportPath?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (exportPath !== undefined && exportPath !== null) {
            queryParameters = queryParameters.set('exportPath', <any>exportPath);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/v1/Punch/exportAllAttachments`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param projectId 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllNewPunchesPaged(body?: NewPagedBody, projectId?: number, templateId?: number, observe?: 'body', reportProgress?: boolean): Observable<PunchViewModelDataSourceResult>;
    public getAllNewPunchesPaged(body?: NewPagedBody, projectId?: number, templateId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchViewModelDataSourceResult>>;
    public getAllNewPunchesPaged(body?: NewPagedBody, projectId?: number, templateId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchViewModelDataSourceResult>>;
    public getAllNewPunchesPaged(body?: NewPagedBody, projectId?: number, templateId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;
        if (projectId !== undefined && projectId !== null) {
            headers = headers.set('projectId', String(projectId));
        }
        if (templateId !== undefined && templateId !== null) {
            headers = headers.set('templateId', String(templateId));
        }

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchViewModelDataSourceResult>('post',`${this.basePath}/v1/Punch/allPunches/new/Paged`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPagedById(body?: AllPunchesGetIdByCriteriaBody, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getAllPagedById(body?: AllPunchesGetIdByCriteriaBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getAllPagedById(body?: AllPunchesGetIdByCriteriaBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getAllPagedById(body?: AllPunchesGetIdByCriteriaBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<number>>('post',`${this.basePath}/v1/Punch/allPunches/GetIdByCriteria`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param elementId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPagedStartingByElement(elementId: number, body?: PagedElementIdBody, observe?: 'body', reportProgress?: boolean): Observable<PunchViewModelDataSourceResult>;
    public getAllPagedStartingByElement(elementId: number, body?: PagedElementIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchViewModelDataSourceResult>>;
    public getAllPagedStartingByElement(elementId: number, body?: PagedElementIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchViewModelDataSourceResult>>;
    public getAllPagedStartingByElement(elementId: number, body?: PagedElementIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling getAllPagedStartingByElement.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchViewModelDataSourceResult>('post',`${this.basePath}/v1/Punch/allPunchesStartingByElement/Paged/${encodeURIComponent(String(elementId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param elementId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPagedStartingByElementHCS(elementId: number, body?: PagedElementIdBody4, observe?: 'body', reportProgress?: boolean): Observable<PunchViewModelDataSourceResult>;
    public getAllPagedStartingByElementHCS(elementId: number, body?: PagedElementIdBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchViewModelDataSourceResult>>;
    public getAllPagedStartingByElementHCS(elementId: number, body?: PagedElementIdBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchViewModelDataSourceResult>>;
    public getAllPagedStartingByElementHCS(elementId: number, body?: PagedElementIdBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling getAllPagedStartingByElementHCS.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchViewModelDataSourceResult>('post',`${this.basePath}/v1/Punch/allPunchesStartingByElementHCS/Paged/${encodeURIComponent(String(elementId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPunches(observe?: 'body', reportProgress?: boolean): Observable<Array<PunchViewModel>>;
    public getAllPunches(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PunchViewModel>>>;
    public getAllPunches(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PunchViewModel>>>;
    public getAllPunches(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PunchViewModel>>('get',`${this.basePath}/v1/Punch/allPunches`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param sendCover 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPunchesPaged(body?: AllPunchesPagedBody, sendCover?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PunchViewModelDataSourceResult>;
    public getAllPunchesPaged(body?: AllPunchesPagedBody, sendCover?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchViewModelDataSourceResult>>;
    public getAllPunchesPaged(body?: AllPunchesPagedBody, sendCover?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchViewModelDataSourceResult>>;
    public getAllPunchesPaged(body?: AllPunchesPagedBody, sendCover?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sendCover !== undefined && sendCover !== null) {
            queryParameters = queryParameters.set('sendCover', <any>sendCover);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchViewModelDataSourceResult>('post',`${this.basePath}/v1/Punch/allPunches/Paged`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param sendCover 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPunchesPagedOBSOLETED(body?: PagedOBSOLETEDBody, sendCover?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PunchViewModelDataSourceResult>;
    public getAllPunchesPagedOBSOLETED(body?: PagedOBSOLETEDBody, sendCover?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchViewModelDataSourceResult>>;
    public getAllPunchesPagedOBSOLETED(body?: PagedOBSOLETEDBody, sendCover?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchViewModelDataSourceResult>>;
    public getAllPunchesPagedOBSOLETED(body?: PagedOBSOLETEDBody, sendCover?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sendCover !== undefined && sendCover !== null) {
            queryParameters = queryParameters.set('sendCover', <any>sendCover);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchViewModelDataSourceResult>('post',`${this.basePath}/v1/Punch/allPunches/Paged/OBSOLETED`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSignableById(body?: PunchSignPunchesPreviewBody, observe?: 'body', reportProgress?: boolean): Observable<Array<PunchViewModel>>;
    public getAllSignableById(body?: PunchSignPunchesPreviewBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PunchViewModel>>>;
    public getAllSignableById(body?: PunchSignPunchesPreviewBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PunchViewModel>>>;
    public getAllSignableById(body?: PunchSignPunchesPreviewBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PunchViewModel>>('post',`${this.basePath}/v1/Punch/SignPunchesPreview`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSignableByIdExcel(body?: PunchSignPunchesPreviewExcelBody, observe?: 'body', reportProgress?: boolean): Observable<ImportViewModel>;
    public getAllSignableByIdExcel(body?: PunchSignPunchesPreviewExcelBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportViewModel>>;
    public getAllSignableByIdExcel(body?: PunchSignPunchesPreviewExcelBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportViewModel>>;
    public getAllSignableByIdExcel(body?: PunchSignPunchesPreviewExcelBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ImportViewModel>('post',`${this.basePath}/v1/Punch/SignPunchesPreviewExcel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColumnsForAdvancedFiltersByProjectTemplate(observe?: 'body', reportProgress?: boolean): Observable<Array<PunchAvailableColumnViewModel>>;
    public getColumnsForAdvancedFiltersByProjectTemplate(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PunchAvailableColumnViewModel>>>;
    public getColumnsForAdvancedFiltersByProjectTemplate(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PunchAvailableColumnViewModel>>>;
    public getColumnsForAdvancedFiltersByProjectTemplate(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PunchAvailableColumnViewModel>>('get',`${this.basePath}/v1/Punch/ColumnsForAdvancedFiltersByProjectTemplate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchAttachmentsActionsTemplate(body?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<ExportViewModel>;
    public getPunchAttachmentsActionsTemplate(body?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExportViewModel>>;
    public getPunchAttachmentsActionsTemplate(body?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExportViewModel>>;
    public getPunchAttachmentsActionsTemplate(body?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ExportViewModel>('post',`${this.basePath}/v1/Punch/PunchAttachmentsTemplate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param zipFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchAttachmentsActionsTemplatePreviewForm(zipFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ResultDetailsTemplateViewModel>;
    public getPunchAttachmentsActionsTemplatePreviewForm(zipFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultDetailsTemplateViewModel>>;
    public getPunchAttachmentsActionsTemplatePreviewForm(zipFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultDetailsTemplateViewModel>>;
    public getPunchAttachmentsActionsTemplatePreviewForm(zipFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (zipFile !== undefined) {
            formParams = formParams.append('zipFile', <any>zipFile) as any || formParams;
        }

        return this.httpClient.request<ResultDetailsTemplateViewModel>('post',`${this.basePath}/v1/Punch/PunchAttachmentsActionsTemplatePreview`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param punchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchById(punchId: number, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public getPunchById(punchId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public getPunchById(punchId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public getPunchById(punchId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (punchId === null || punchId === undefined) {
            throw new Error('Required parameter punchId was null or undefined when calling getPunchById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PunchDetailViewModel>('get',`${this.basePath}/v1/Punch/${encodeURIComponent(String(punchId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchesUpdateTemplate(body?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<ExportViewModel>;
    public getPunchesUpdateTemplate(body?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExportViewModel>>;
    public getPunchesUpdateTemplate(body?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExportViewModel>>;
    public getPunchesUpdateTemplate(body?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ExportViewModel>('post',`${this.basePath}/v1/Punch/PunchesUpdateTemplate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param zipFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchesUpdateTemplatePreviewForm(zipFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<PunchUpdatePreviewViewModel>;
    public getPunchesUpdateTemplatePreviewForm(zipFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchUpdatePreviewViewModel>>;
    public getPunchesUpdateTemplatePreviewForm(zipFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchUpdatePreviewViewModel>>;
    public getPunchesUpdateTemplatePreviewForm(zipFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (zipFile !== undefined) {
            formParams = formParams.append('zipFile', <any>zipFile) as any || formParams;
        }

        return this.httpClient.request<PunchUpdatePreviewViewModel>('post',`${this.basePath}/v1/Punch/PunchesUpdateTemplatePreview`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param punchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isPunchActive(punchId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isPunchActive(punchId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isPunchActive(punchId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isPunchActive(punchId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (punchId === null || punchId === undefined) {
            throw new Error('Required parameter punchId was null or undefined when calling isPunchActive.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/v1/Punch/isActive/${encodeURIComponent(String(punchId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param punchId 
     * @param projectId 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isSignable(punchId: number, projectId?: number, templateId?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isSignable(punchId: number, projectId?: number, templateId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isSignable(punchId: number, projectId?: number, templateId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isSignable(punchId: number, projectId?: number, templateId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (punchId === null || punchId === undefined) {
            throw new Error('Required parameter punchId was null or undefined when calling isSignable.');
        }



        let headers = this.defaultHeaders;
        if (projectId !== undefined && projectId !== null) {
            headers = headers.set('projectId', String(projectId));
        }
        if (templateId !== undefined && templateId !== null) {
            headers = headers.set('templateId', String(templateId));
        }

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/v1/Punch/${encodeURIComponent(String(punchId))}/isSignable`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyBulkInspectionPunches(body?: { [key: string]: any; }, observe?: 'body', reportProgress?: boolean): Observable<Array<PunchViewModel>>;
    public modifyBulkInspectionPunches(body?: { [key: string]: any; }, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PunchViewModel>>>;
    public modifyBulkInspectionPunches(body?: { [key: string]: any; }, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PunchViewModel>>>;
    public modifyBulkInspectionPunches(body?: { [key: string]: any; }, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PunchViewModel>>('put',`${this.basePath}/v1/Punch/BulkModify`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param value 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onDispute(id: number, value: boolean, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public onDispute(id: number, value: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public onDispute(id: number, value: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public onDispute(id: number, value: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling onDispute.');
        }

        if (value === null || value === undefined) {
            throw new Error('Required parameter value was null or undefined when calling onDispute.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch/OnDispute/${encodeURIComponent(String(id))}/${encodeURIComponent(String(value))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCreateMedia(body?: PunchCreateMediaBody, observe?: 'body', reportProgress?: boolean): Observable<PunchMediaViewModel>;
    public postCreateMedia(body?: PunchCreateMediaBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchMediaViewModel>>;
    public postCreateMedia(body?: PunchCreateMediaBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchMediaViewModel>>;
    public postCreateMedia(body?: PunchCreateMediaBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchMediaViewModel>('post',`${this.basePath}/v1/Punch/CreateMedia`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param exportWithoutSigns 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postExportPunches(body?: PunchExportPunchesBody, exportWithoutSigns?: boolean, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public postExportPunches(body?: PunchExportPunchesBody, exportWithoutSigns?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public postExportPunches(body?: PunchExportPunchesBody, exportWithoutSigns?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public postExportPunches(body?: PunchExportPunchesBody, exportWithoutSigns?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (exportWithoutSigns !== undefined && exportWithoutSigns !== null) {
            queryParameters = queryParameters.set('exportWithoutSigns', <any>exportWithoutSigns);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/v1/Punch/ExportPunches`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPunchAndReturnId(body?: PunchPostPunchAndReturnIdBody, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public postPunchAndReturnId(body?: PunchPostPunchAndReturnIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public postPunchAndReturnId(body?: PunchPostPunchAndReturnIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public postPunchAndReturnId(body?: PunchPostPunchAndReturnIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch/PostPunchAndReturnId`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postSetMediaCover(id: number, observe?: 'body', reportProgress?: boolean): Observable<PunchMediaViewModel>;
    public postSetMediaCover(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchMediaViewModel>>;
    public postSetMediaCover(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchMediaViewModel>>;
    public postSetMediaCover(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postSetMediaCover.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PunchMediaViewModel>('put',`${this.basePath}/v1/Punch/Media/${encodeURIComponent(String(id))}/Cover`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param saveConfig 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTransferPunch(saveConfig: boolean, body?: TransferSaveConfigBody, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public postTransferPunch(saveConfig: boolean, body?: TransferSaveConfigBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public postTransferPunch(saveConfig: boolean, body?: TransferSaveConfigBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public postTransferPunch(saveConfig: boolean, body?: TransferSaveConfigBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (saveConfig === null || saveConfig === undefined) {
            throw new Error('Required parameter saveConfig was null or undefined when calling postTransferPunch.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/v1/Punch/Transfer/${encodeURIComponent(String(saveConfig))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTransferPunchPreview(body?: PunchTransferPreviewBody, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public postTransferPunchPreview(body?: PunchTransferPreviewBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public postTransferPunchPreview(body?: PunchTransferPreviewBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public postTransferPunchPreview(body?: PunchTransferPreviewBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/v1/Punch/TransferPreview`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postpunch(body?: V1PunchBody4, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public postpunch(body?: V1PunchBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public postpunch(body?: V1PunchBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public postpunch(body?: V1PunchBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putPunchAndReturnId(body?: PunchPutPunchAndReturnIdBody, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public putPunchAndReturnId(body?: PunchPutPunchAndReturnIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public putPunchAndReturnId(body?: PunchPutPunchAndReturnIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public putPunchAndReturnId(body?: PunchPutPunchAndReturnIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('put',`${this.basePath}/v1/Punch/PutPunchAndReturnId`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putpunch(body?: V1PunchBody, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public putpunch(body?: V1PunchBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public putpunch(body?: V1PunchBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public putpunch(body?: V1PunchBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('put',`${this.basePath}/v1/Punch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rejectPunchById(body?: PunchRejectBody, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public rejectPunchById(body?: PunchRejectBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public rejectPunchById(body?: PunchRejectBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public rejectPunchById(body?: PunchRejectBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch/Reject`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rejectPunchByIds(body?: PunchRejectByIdsBody, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public rejectPunchByIds(body?: PunchRejectByIdsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public rejectPunchByIds(body?: PunchRejectByIdsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public rejectPunchByIds(body?: PunchRejectByIdsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch/RejectByIds`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public remarkPunchById(body?: PunchRemarkBody4, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public remarkPunchById(body?: PunchRemarkBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public remarkPunchById(body?: PunchRemarkBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public remarkPunchById(body?: PunchRemarkBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch/Remark`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param zipFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public savePunchAttachmentsActionsTemplateForm(zipFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public savePunchAttachmentsActionsTemplateForm(zipFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public savePunchAttachmentsActionsTemplateForm(zipFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public savePunchAttachmentsActionsTemplateForm(zipFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (zipFile !== undefined) {
            formParams = formParams.append('zipFile', <any>zipFile) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/v1/Punch/SavePunchAttachmentsActionsTemplate/Process`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param zipFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public savePunchesUpdateTemplatePreviewForm(zipFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<IActionResult>;
    public savePunchesUpdateTemplatePreviewForm(zipFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IActionResult>>;
    public savePunchesUpdateTemplatePreviewForm(zipFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IActionResult>>;
    public savePunchesUpdateTemplatePreviewForm(zipFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (zipFile !== undefined) {
            formParams = formParams.append('zipFile', <any>zipFile) as any || formParams;
        }

        return this.httpClient.request<IActionResult>('put',`${this.basePath}/v1/Punch/SavePunchesUpdateTemplate`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param zipFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public savePunchesUpdateTemplatePreviewProcessForm(zipFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public savePunchesUpdateTemplatePreviewProcessForm(zipFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public savePunchesUpdateTemplatePreviewProcessForm(zipFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public savePunchesUpdateTemplatePreviewProcessForm(zipFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (zipFile !== undefined) {
            formParams = formParams.append('zipFile', <any>zipFile) as any || formParams;
        }

        return this.httpClient.request<number>('put',`${this.basePath}/v1/Punch/SavePunchesUpdateTemplate/Process`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signPunchById(body?: PunchSignBody, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public signPunchById(body?: PunchSignBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public signPunchById(body?: PunchSignBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public signPunchById(body?: PunchSignBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch/Sign`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signPunchByIds(body?: PunchSignByIdsBody, observe?: 'body', reportProgress?: boolean): Observable<PunchDetailViewModel>;
    public signPunchByIds(body?: PunchSignByIdsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchDetailViewModel>>;
    public signPunchByIds(body?: PunchSignByIdsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchDetailViewModel>>;
    public signPunchByIds(body?: PunchSignByIdsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchDetailViewModel>('post',`${this.basePath}/v1/Punch/SignByIds`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
