/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllInspectionsPagedBody } from '../model/allInspectionsPagedBody';
import { AllInspectionsPagedBody1 } from '../model/allInspectionsPagedBody1';
import { AllInspectionsPagedBody2 } from '../model/allInspectionsPagedBody2';
import { AllInspectionsPagedBody3 } from '../model/allInspectionsPagedBody3';
import { AttendeeViewModel } from '../model/attendeeViewModel';
import { ElementOutDto } from '../model/elementOutDto';
import { ExportViewModel } from '../model/exportViewModel';
import { ImportViewModel } from '../model/importViewModel';
import { InspectionAttendeeBody } from '../model/inspectionAttendeeBody';
import { InspectionAttendeeBody1 } from '../model/inspectionAttendeeBody1';
import { InspectionAttendeeBody2 } from '../model/inspectionAttendeeBody2';
import { InspectionAttendeeBody3 } from '../model/inspectionAttendeeBody3';
import { InspectionCancelBody } from '../model/inspectionCancelBody';
import { InspectionCancelBody1 } from '../model/inspectionCancelBody1';
import { InspectionCancelBody2 } from '../model/inspectionCancelBody2';
import { InspectionCancelBody3 } from '../model/inspectionCancelBody3';
import { InspectionCompletedBody } from '../model/inspectionCompletedBody';
import { InspectionCompletedBody1 } from '../model/inspectionCompletedBody1';
import { InspectionCompletedBody2 } from '../model/inspectionCompletedBody2';
import { InspectionCompletedBody3 } from '../model/inspectionCompletedBody3';
import { InspectionConfirmAttendeeBody } from '../model/inspectionConfirmAttendeeBody';
import { InspectionConfirmAttendeeBody1 } from '../model/inspectionConfirmAttendeeBody1';
import { InspectionConfirmAttendeeBody2 } from '../model/inspectionConfirmAttendeeBody2';
import { InspectionConfirmAttendeeBody3 } from '../model/inspectionConfirmAttendeeBody3';
import { InspectionConsolidateAttendeeUnknownBody } from '../model/inspectionConsolidateAttendeeUnknownBody';
import { InspectionConsolidateAttendeeUnknownBody1 } from '../model/inspectionConsolidateAttendeeUnknownBody1';
import { InspectionConsolidateAttendeeUnknownBody2 } from '../model/inspectionConsolidateAttendeeUnknownBody2';
import { InspectionConsolidateAttendeeUnknownBody3 } from '../model/inspectionConsolidateAttendeeUnknownBody3';
import { InspectionDetailDto } from '../model/inspectionDetailDto';
import { InspectionDetailViewModel } from '../model/inspectionDetailViewModel';
import { InspectionDiscardAttendeeUnknownBody } from '../model/inspectionDiscardAttendeeUnknownBody';
import { InspectionDiscardAttendeeUnknownBody1 } from '../model/inspectionDiscardAttendeeUnknownBody1';
import { InspectionDiscardAttendeeUnknownBody2 } from '../model/inspectionDiscardAttendeeUnknownBody2';
import { InspectionDiscardAttendeeUnknownBody3 } from '../model/inspectionDiscardAttendeeUnknownBody3';
import { InspectionIdApprovePunchesBody } from '../model/inspectionIdApprovePunchesBody';
import { InspectionIdApprovePunchesBody1 } from '../model/inspectionIdApprovePunchesBody1';
import { InspectionIdApprovePunchesBody2 } from '../model/inspectionIdApprovePunchesBody2';
import { InspectionIdApprovePunchesBody3 } from '../model/inspectionIdApprovePunchesBody3';
import { InspectionIdDiscardPunchesBody } from '../model/inspectionIdDiscardPunchesBody';
import { InspectionIdDiscardPunchesBody1 } from '../model/inspectionIdDiscardPunchesBody1';
import { InspectionIdDiscardPunchesBody2 } from '../model/inspectionIdDiscardPunchesBody2';
import { InspectionIdDiscardPunchesBody3 } from '../model/inspectionIdDiscardPunchesBody3';
import { InspectionIdFileBody } from '../model/inspectionIdFileBody';
import { InspectionIdFileBody1 } from '../model/inspectionIdFileBody1';
import { InspectionIdFileBody2 } from '../model/inspectionIdFileBody2';
import { InspectionIdFileBody3 } from '../model/inspectionIdFileBody3';
import { InspectionIdFileBody4 } from '../model/inspectionIdFileBody4';
import { InspectionIdFileBody5 } from '../model/inspectionIdFileBody5';
import { InspectionIdFileBody6 } from '../model/inspectionIdFileBody6';
import { InspectionIdFileBody7 } from '../model/inspectionIdFileBody7';
import { InspectionIdModifyPunchesBody } from '../model/inspectionIdModifyPunchesBody';
import { InspectionIdModifyPunchesBody1 } from '../model/inspectionIdModifyPunchesBody1';
import { InspectionIdModifyPunchesBody2 } from '../model/inspectionIdModifyPunchesBody2';
import { InspectionIdModifyPunchesBody3 } from '../model/inspectionIdModifyPunchesBody3';
import { InspectionImportPunchesBody } from '../model/inspectionImportPunchesBody';
import { InspectionImportPunchesBody1 } from '../model/inspectionImportPunchesBody1';
import { InspectionImportPunchesBody2 } from '../model/inspectionImportPunchesBody2';
import { InspectionImportPunchesBody3 } from '../model/inspectionImportPunchesBody3';
import { InspectionImportPunchesMultipleInspectionBody } from '../model/inspectionImportPunchesMultipleInspectionBody';
import { InspectionImportPunchesMultipleInspectionBody1 } from '../model/inspectionImportPunchesMultipleInspectionBody1';
import { InspectionImportPunchesMultipleInspectionBody2 } from '../model/inspectionImportPunchesMultipleInspectionBody2';
import { InspectionImportPunchesMultipleInspectionBody3 } from '../model/inspectionImportPunchesMultipleInspectionBody3';
import { InspectionImportPunchesPreviewBody } from '../model/inspectionImportPunchesPreviewBody';
import { InspectionImportPunchesPreviewBody1 } from '../model/inspectionImportPunchesPreviewBody1';
import { InspectionImportPunchesPreviewBody2 } from '../model/inspectionImportPunchesPreviewBody2';
import { InspectionImportPunchesPreviewBody3 } from '../model/inspectionImportPunchesPreviewBody3';
import { InspectionMediaViewModel } from '../model/inspectionMediaViewModel';
import { InspectionMeetingPointBody } from '../model/inspectionMeetingPointBody';
import { InspectionMeetingPointBody1 } from '../model/inspectionMeetingPointBody1';
import { InspectionMeetingPointBody2 } from '../model/inspectionMeetingPointBody2';
import { InspectionMeetingPointBody3 } from '../model/inspectionMeetingPointBody3';
import { InspectionPauseBody } from '../model/inspectionPauseBody';
import { InspectionPauseBody1 } from '../model/inspectionPauseBody1';
import { InspectionPauseBody2 } from '../model/inspectionPauseBody2';
import { InspectionPauseBody3 } from '../model/inspectionPauseBody3';
import { InspectionRemarkViewModel } from '../model/inspectionRemarkViewModel';
import { InspectionRescheduleBody } from '../model/inspectionRescheduleBody';
import { InspectionRescheduleBody1 } from '../model/inspectionRescheduleBody1';
import { InspectionRescheduleBody2 } from '../model/inspectionRescheduleBody2';
import { InspectionRescheduleBody3 } from '../model/inspectionRescheduleBody3';
import { InspectionStartBody } from '../model/inspectionStartBody';
import { InspectionStartBody1 } from '../model/inspectionStartBody1';
import { InspectionStartBody2 } from '../model/inspectionStartBody2';
import { InspectionStartBody3 } from '../model/inspectionStartBody3';
import { InspectionViewModel } from '../model/inspectionViewModel';
import { InspectionViewModelDataSourceResult } from '../model/inspectionViewModelDataSourceResult';
import { ItemsAddElemntsBody } from '../model/itemsAddElemntsBody';
import { ItemsAddElemntsBody1 } from '../model/itemsAddElemntsBody1';
import { ItemsAddElemntsBody2 } from '../model/itemsAddElemntsBody2';
import { ItemsAddElemntsBody3 } from '../model/itemsAddElemntsBody3';
import { PunchImportMultipleInspectionsPreviewViewModel } from '../model/punchImportMultipleInspectionsPreviewViewModel';
import { PunchImportPreviewViewModel } from '../model/punchImportPreviewViewModel';
import { UserInspectionViewModel } from '../model/userInspectionViewModel';
import { UserWalkOutDto } from '../model/userWalkOutDto';
import { V1InspectionBody } from '../model/v1InspectionBody';
import { V1InspectionBody1 } from '../model/v1InspectionBody1';
import { V1InspectionBody2 } from '../model/v1InspectionBody2';
import { V1InspectionBody3 } from '../model/v1InspectionBody3';
import { V1InspectionBody4 } from '../model/v1InspectionBody4';
import { V1InspectionBody5 } from '../model/v1InspectionBody5';
import { V1InspectionBody6 } from '../model/v1InspectionBody6';
import { V1InspectionBody7 } from '../model/v1InspectionBody7';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InspectionService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param inspectionMemberId 
     * @param accept 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptInvitation(inspectionMemberId: string, accept: boolean, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public acceptInvitation(inspectionMemberId: string, accept: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public acceptInvitation(inspectionMemberId: string, accept: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public acceptInvitation(inspectionMemberId: string, accept: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionMemberId === null || inspectionMemberId === undefined) {
            throw new Error('Required parameter inspectionMemberId was null or undefined when calling acceptInvitation.');
        }

        if (accept === null || accept === undefined) {
            throw new Error('Required parameter accept was null or undefined when calling acceptInvitation.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/AcceptInvitation/${encodeURIComponent(String(inspectionMemberId))}/${encodeURIComponent(String(accept))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approveInspectionPunches(inspectionId: number, body?: InspectionIdApprovePunchesBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public approveInspectionPunches(inspectionId: number, body?: InspectionIdApprovePunchesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public approveInspectionPunches(inspectionId: number, body?: InspectionIdApprovePunchesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public approveInspectionPunches(inspectionId: number, body?: InspectionIdApprovePunchesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling approveInspectionPunches.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/ApprovePunches`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkInspectionNameAvailability(inspectionName: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkInspectionNameAvailability(inspectionName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkInspectionNameAvailability(inspectionName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkInspectionNameAvailability(inspectionName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionName === null || inspectionName === undefined) {
            throw new Error('Required parameter inspectionName was null or undefined when calling checkInspectionNameAvailability.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/v1/Inspection/checkNameAvailability/${encodeURIComponent(String(inspectionName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public consolidateInspectionPunchImport(body?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<PunchImportMultipleInspectionsPreviewViewModel>;
    public consolidateInspectionPunchImport(body?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchImportMultipleInspectionsPreviewViewModel>>;
    public consolidateInspectionPunchImport(body?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchImportMultipleInspectionsPreviewViewModel>>;
    public consolidateInspectionPunchImport(body?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchImportMultipleInspectionsPreviewViewModel>('post',`${this.basePath}/v1/Inspection/ConsolidateMultipleInspection`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAttendeeInspection(inspectionId: number, userId: string, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public deleteAttendeeInspection(inspectionId: number, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public deleteAttendeeInspection(inspectionId: number, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public deleteAttendeeInspection(inspectionId: number, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling deleteAttendeeInspection.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteAttendeeInspection.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('delete',`${this.basePath}/v1/Inspection/Attendee/${encodeURIComponent(String(inspectionId))}/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInspection(inspectionId?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteInspection(inspectionId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteInspection(inspectionId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteInspection(inspectionId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inspectionId !== undefined && inspectionId !== null) {
            queryParameters = queryParameters.set('inspectionId', <any>inspectionId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/Inspection`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param inspectionFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInspectionMediaInspection(inspectionId: number, inspectionFileId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public deleteInspectionMediaInspection(inspectionId: number, inspectionFileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public deleteInspectionMediaInspection(inspectionId: number, inspectionFileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public deleteInspectionMediaInspection(inspectionId: number, inspectionFileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling deleteInspectionMediaInspection.');
        }

        if (inspectionFileId === null || inspectionFileId === undefined) {
            throw new Error('Required parameter inspectionFileId was null or undefined when calling deleteInspectionMediaInspection.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('delete',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/File/${encodeURIComponent(String(inspectionFileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discardInspectionPunches(inspectionId: number, body?: InspectionIdDiscardPunchesBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public discardInspectionPunches(inspectionId: number, body?: InspectionIdDiscardPunchesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public discardInspectionPunches(inspectionId: number, body?: InspectionIdDiscardPunchesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public discardInspectionPunches(inspectionId: number, body?: InspectionIdDiscardPunchesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling discardInspectionPunches.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/DiscardPunches`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param toTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllInspectionWithTransferConfig(toTemplateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionViewModel>>;
    public getAllInspectionWithTransferConfig(toTemplateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionViewModel>>>;
    public getAllInspectionWithTransferConfig(toTemplateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionViewModel>>>;
    public getAllInspectionWithTransferConfig(toTemplateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (toTemplateId === null || toTemplateId === undefined) {
            throw new Error('Required parameter toTemplateId was null or undefined when calling getAllInspectionWithTransferConfig.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionViewModel>>('get',`${this.basePath}/v1/Inspection/allInspections/${encodeURIComponent(String(toTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param start 
     * @param end 
     * @param allInfo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllInspectionsByDates(start: Date, end: Date, allInfo: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionViewModel>>;
    public getAllInspectionsByDates(start: Date, end: Date, allInfo: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionViewModel>>>;
    public getAllInspectionsByDates(start: Date, end: Date, allInfo: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionViewModel>>>;
    public getAllInspectionsByDates(start: Date, end: Date, allInfo: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getAllInspectionsByDates.');
        }

        if (end === null || end === undefined) {
            throw new Error('Required parameter end was null or undefined when calling getAllInspectionsByDates.');
        }

        if (allInfo === null || allInfo === undefined) {
            throw new Error('Required parameter allInfo was null or undefined when calling getAllInspectionsByDates.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionViewModel>>('get',`${this.basePath}/v1/Inspection/allInspectionsByDates/${encodeURIComponent(String(start))}/${encodeURIComponent(String(end))}/${encodeURIComponent(String(allInfo))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllInspectionsPaged(body?: AllInspectionsPagedBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionViewModelDataSourceResult>;
    public getAllInspectionsPaged(body?: AllInspectionsPagedBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionViewModelDataSourceResult>>;
    public getAllInspectionsPaged(body?: AllInspectionsPagedBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionViewModelDataSourceResult>>;
    public getAllInspectionsPaged(body?: AllInspectionsPagedBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionViewModelDataSourceResult>('post',`${this.basePath}/v1/Inspection/allInspections/Paged`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAttendeesByInspectionId(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<UserInspectionViewModel>>;
    public getAttendeesByInspectionId(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserInspectionViewModel>>>;
    public getAttendeesByInspectionId(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserInspectionViewModel>>>;
    public getAttendeesByInspectionId(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling getAttendeesByInspectionId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserInspectionViewModel>>('get',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/Attendees`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param allInfo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionById(inspectionId: number, allInfo: boolean, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public getInspectionById(inspectionId: number, allInfo: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public getInspectionById(inspectionId: number, allInfo: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public getInspectionById(inspectionId: number, allInfo: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling getInspectionById.');
        }

        if (allInfo === null || allInfo === undefined) {
            throw new Error('Required parameter allInfo was null or undefined when calling getInspectionById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('get',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/${encodeURIComponent(String(allInfo))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param fileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionMediaInspection(inspectionId: number, fileId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionMediaViewModel>;
    public getInspectionMediaInspection(inspectionId: number, fileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionMediaViewModel>>;
    public getInspectionMediaInspection(inspectionId: number, fileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionMediaViewModel>>;
    public getInspectionMediaInspection(inspectionId: number, fileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling getInspectionMediaInspection.');
        }

        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling getInspectionMediaInspection.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionMediaViewModel>('get',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/File/${encodeURIComponent(String(fileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionMediasInspection(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionMediaViewModel>>;
    public getInspectionMediasInspection(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionMediaViewModel>>>;
    public getInspectionMediasInspection(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionMediaViewModel>>>;
    public getInspectionMediasInspection(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling getInspectionMediasInspection.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionMediaViewModel>>('get',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/Files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionSelectedItems(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ElementOutDto>>;
    public getInspectionSelectedItems(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElementOutDto>>>;
    public getInspectionSelectedItems(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElementOutDto>>>;
    public getInspectionSelectedItems(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling getInspectionSelectedItems.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElementOutDto>>('get',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/Items`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param multipleInspections 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchTemplate(multipleInspections?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ExportViewModel>;
    public getPunchTemplate(multipleInspections?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExportViewModel>>;
    public getPunchTemplate(multipleInspections?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExportViewModel>>;
    public getPunchTemplate(multipleInspections?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multipleInspections !== undefined && multipleInspections !== null) {
            queryParameters = queryParameters.set('multipleInspections', <any>multipleInspections);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ExportViewModel>('get',`${this.basePath}/v1/Inspection/PunchesTemplate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRemarksByInspectionId(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionRemarkViewModel>>;
    public getRemarksByInspectionId(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionRemarkViewModel>>>;
    public getRemarksByInspectionId(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionRemarkViewModel>>>;
    public getRemarksByInspectionId(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling getRemarksByInspectionId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionRemarkViewModel>>('get',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/Remarks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserWalkByInspectionId(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<UserWalkOutDto>>;
    public getUserWalkByInspectionId(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserWalkOutDto>>>;
    public getUserWalkByInspectionId(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserWalkOutDto>>>;
    public getUserWalkByInspectionId(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling getUserWalkByInspectionId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserWalkOutDto>>('get',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/UserWalks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param addElemnts 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inspectionSelectedItems(inspectionId: number, addElemnts: boolean, body?: ItemsAddElemntsBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public inspectionSelectedItems(inspectionId: number, addElemnts: boolean, body?: ItemsAddElemntsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public inspectionSelectedItems(inspectionId: number, addElemnts: boolean, body?: ItemsAddElemntsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public inspectionSelectedItems(inspectionId: number, addElemnts: boolean, body?: ItemsAddElemntsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling inspectionSelectedItems.');
        }

        if (addElemnts === null || addElemnts === undefined) {
            throw new Error('Required parameter addElemnts was null or undefined when calling inspectionSelectedItems.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/Items/${encodeURIComponent(String(addElemnts))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyInspectionPunches(inspectionId: number, body?: InspectionIdModifyPunchesBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public modifyInspectionPunches(inspectionId: number, body?: InspectionIdModifyPunchesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public modifyInspectionPunches(inspectionId: number, body?: InspectionIdModifyPunchesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public modifyInspectionPunches(inspectionId: number, body?: InspectionIdModifyPunchesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling modifyInspectionPunches.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/ModifyPunches`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAttendeeInspection(body?: InspectionAttendeeBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postAttendeeInspection(body?: InspectionAttendeeBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postAttendeeInspection(body?: InspectionAttendeeBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postAttendeeInspection(body?: InspectionAttendeeBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/Attendee`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAttendeesInspection(body?: Array<AttendeeViewModel>, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postAttendeesInspection(body?: Array<AttendeeViewModel>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postAttendeesInspection(body?: Array<AttendeeViewModel>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postAttendeesInspection(body?: Array<AttendeeViewModel>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/Attendees`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postBackToCompletedStatus(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postBackToCompletedStatus(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postBackToCompletedStatus(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postBackToCompletedStatus(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling postBackToCompletedStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/BackToCompletedStatus/${encodeURIComponent(String(inspectionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCancelInspection(body?: InspectionCancelBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailViewModel>;
    public postCancelInspection(body?: InspectionCancelBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailViewModel>>;
    public postCancelInspection(body?: InspectionCancelBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailViewModel>>;
    public postCancelInspection(body?: InspectionCancelBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailViewModel>('post',`${this.basePath}/v1/Inspection/Cancel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCompletedInspection(body?: InspectionCompletedBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postCompletedInspection(body?: InspectionCompletedBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postCompletedInspection(body?: InspectionCompletedBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postCompletedInspection(body?: InspectionCompletedBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/Completed`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmAttendeeInspection(body?: InspectionConfirmAttendeeBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postConfirmAttendeeInspection(body?: InspectionConfirmAttendeeBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postConfirmAttendeeInspection(body?: InspectionConfirmAttendeeBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postConfirmAttendeeInspection(body?: InspectionConfirmAttendeeBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/ConfirmAttendee`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConsolidatedInspection(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postConsolidatedInspection(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postConsolidatedInspection(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postConsolidatedInspection(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling postConsolidatedInspection.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/Consolidated/${encodeURIComponent(String(inspectionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param templateId 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postImportInspectionPreview(projectId?: number, templateId?: number, inspectionId?: number, observe?: 'body', reportProgress?: boolean): Observable<ImportViewModel>;
    public postImportInspectionPreview(projectId?: number, templateId?: number, inspectionId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportViewModel>>;
    public postImportInspectionPreview(projectId?: number, templateId?: number, inspectionId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportViewModel>>;
    public postImportInspectionPreview(projectId?: number, templateId?: number, inspectionId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }
        if (templateId !== undefined && templateId !== null) {
            queryParameters = queryParameters.set('templateId', <any>templateId);
        }
        if (inspectionId !== undefined && inspectionId !== null) {
            queryParameters = queryParameters.set('inspectionId', <any>inspectionId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ImportViewModel>('post',`${this.basePath}/v1/Inspection/ExportItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postInspection(body?: V1InspectionBody4, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postInspection(body?: V1InspectionBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postInspection(body?: V1InspectionBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postInspection(body?: V1InspectionBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postInspectionMediaInspection(inspectionId: number, body?: InspectionIdFileBody4, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postInspectionMediaInspection(inspectionId: number, body?: InspectionIdFileBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postInspectionMediaInspection(inspectionId: number, body?: InspectionIdFileBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postInspectionMediaInspection(inspectionId: number, body?: InspectionIdFileBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling postInspectionMediaInspection.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/File`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postInspectionPunchImport(body?: InspectionImportPunchesBody, observe?: 'body', reportProgress?: boolean): Observable<PunchImportPreviewViewModel>;
    public postInspectionPunchImport(body?: InspectionImportPunchesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchImportPreviewViewModel>>;
    public postInspectionPunchImport(body?: InspectionImportPunchesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchImportPreviewViewModel>>;
    public postInspectionPunchImport(body?: InspectionImportPunchesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchImportPreviewViewModel>('post',`${this.basePath}/v1/Inspection/ImportPunches`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postInspectionPunchImportPreview(body?: InspectionImportPunchesPreviewBody, observe?: 'body', reportProgress?: boolean): Observable<PunchImportPreviewViewModel>;
    public postInspectionPunchImportPreview(body?: InspectionImportPunchesPreviewBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchImportPreviewViewModel>>;
    public postInspectionPunchImportPreview(body?: InspectionImportPunchesPreviewBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchImportPreviewViewModel>>;
    public postInspectionPunchImportPreview(body?: InspectionImportPunchesPreviewBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchImportPreviewViewModel>('post',`${this.basePath}/v1/Inspection/ImportPunchesPreview`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param isPreview 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMultipleInspectionPunchImport(body?: InspectionImportPunchesMultipleInspectionBody, isPreview?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PunchImportMultipleInspectionsPreviewViewModel>;
    public postMultipleInspectionPunchImport(body?: InspectionImportPunchesMultipleInspectionBody, isPreview?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchImportMultipleInspectionsPreviewViewModel>>;
    public postMultipleInspectionPunchImport(body?: InspectionImportPunchesMultipleInspectionBody, isPreview?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchImportMultipleInspectionsPreviewViewModel>>;
    public postMultipleInspectionPunchImport(body?: InspectionImportPunchesMultipleInspectionBody, isPreview?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isPreview !== undefined && isPreview !== null) {
            queryParameters = queryParameters.set('isPreview', <any>isPreview);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchImportMultipleInspectionsPreviewViewModel>('post',`${this.basePath}/v1/Inspection/ImportPunchesMultipleInspection`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPauseInspection(body?: InspectionPauseBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postPauseInspection(body?: InspectionPauseBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postPauseInspection(body?: InspectionPauseBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postPauseInspection(body?: InspectionPauseBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/Pause`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPreparationInspection(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postPreparationInspection(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postPreparationInspection(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postPreparationInspection(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling postPreparationInspection.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/Preparation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postRescheduleInspection(body?: InspectionRescheduleBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postRescheduleInspection(body?: InspectionRescheduleBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postRescheduleInspection(body?: InspectionRescheduleBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postRescheduleInspection(body?: InspectionRescheduleBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/Reschedule`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postScheduleInspection(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postScheduleInspection(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postScheduleInspection(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postScheduleInspection(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling postScheduleInspection.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/Schedule`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postSendInvitation(inspectionId: number, userId: string, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postSendInvitation(inspectionId: number, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postSendInvitation(inspectionId: number, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postSendInvitation(inspectionId: number, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling postSendInvitation.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling postSendInvitation.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/SendInvitation/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postSendInvitations(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postSendInvitations(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postSendInvitations(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postSendInvitations(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling postSendInvitations.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/SendInvitations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postStartInspection(body?: InspectionStartBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public postStartInspection(body?: InspectionStartBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public postStartInspection(body?: InspectionStartBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public postStartInspection(body?: InspectionStartBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('post',`${this.basePath}/v1/Inspection/Start`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putConsolidateAttendeeInspection(body?: InspectionConsolidateAttendeeUnknownBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public putConsolidateAttendeeInspection(body?: InspectionConsolidateAttendeeUnknownBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public putConsolidateAttendeeInspection(body?: InspectionConsolidateAttendeeUnknownBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public putConsolidateAttendeeInspection(body?: InspectionConsolidateAttendeeUnknownBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/ConsolidateAttendeeUnknown`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putDiscardAttendeeInspection(body?: InspectionDiscardAttendeeUnknownBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public putDiscardAttendeeInspection(body?: InspectionDiscardAttendeeUnknownBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public putDiscardAttendeeInspection(body?: InspectionDiscardAttendeeUnknownBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public putDiscardAttendeeInspection(body?: InspectionDiscardAttendeeUnknownBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/DiscardAttendeeUnknown`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putInspection(body?: V1InspectionBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public putInspection(body?: V1InspectionBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public putInspection(body?: V1InspectionBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public putInspection(body?: V1InspectionBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param inspectionFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putInspectionDefaultDownloadMedia(inspectionId: number, inspectionFileId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionMediaViewModel>>;
    public putInspectionDefaultDownloadMedia(inspectionId: number, inspectionFileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionMediaViewModel>>>;
    public putInspectionDefaultDownloadMedia(inspectionId: number, inspectionFileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionMediaViewModel>>>;
    public putInspectionDefaultDownloadMedia(inspectionId: number, inspectionFileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling putInspectionDefaultDownloadMedia.');
        }

        if (inspectionFileId === null || inspectionFileId === undefined) {
            throw new Error('Required parameter inspectionFileId was null or undefined when calling putInspectionDefaultDownloadMedia.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionMediaViewModel>>('put',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/File/${encodeURIComponent(String(inspectionFileId))}/DefaultDownload`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putInspectionMediaInspection(inspectionId: number, body?: InspectionIdFileBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public putInspectionMediaInspection(inspectionId: number, body?: InspectionIdFileBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public putInspectionMediaInspection(inspectionId: number, body?: InspectionIdFileBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public putInspectionMediaInspection(inspectionId: number, body?: InspectionIdFileBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling putInspectionMediaInspection.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/File`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putInspectionMeetingPoint(body?: InspectionMeetingPointBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public putInspectionMeetingPoint(body?: InspectionMeetingPointBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public putInspectionMeetingPoint(body?: InspectionMeetingPointBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public putInspectionMeetingPoint(body?: InspectionMeetingPointBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/MeetingPoint`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param inspectionFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putInspectionRootMedia(inspectionId: number, inspectionFileId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionMediaViewModel>>;
    public putInspectionRootMedia(inspectionId: number, inspectionFileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionMediaViewModel>>>;
    public putInspectionRootMedia(inspectionId: number, inspectionFileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionMediaViewModel>>>;
    public putInspectionRootMedia(inspectionId: number, inspectionFileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling putInspectionRootMedia.');
        }

        if (inspectionFileId === null || inspectionFileId === undefined) {
            throw new Error('Required parameter inspectionFileId was null or undefined when calling putInspectionRootMedia.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionMediaViewModel>>('put',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/File/${encodeURIComponent(String(inspectionFileId))}/Default`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putOrganizer(inspectionId: number, userId: string, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public putOrganizer(inspectionId: number, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public putOrganizer(inspectionId: number, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public putOrganizer(inspectionId: number, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling putOrganizer.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling putOrganizer.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/${encodeURIComponent(String(inspectionId))}/Organizer/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param inspectionName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInspectionName(inspectionId?: number, inspectionName?: string, observe?: 'body', reportProgress?: boolean): Observable<InspectionDetailDto>;
    public updateInspectionName(inspectionId?: number, inspectionName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionDetailDto>>;
    public updateInspectionName(inspectionId?: number, inspectionName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionDetailDto>>;
    public updateInspectionName(inspectionId?: number, inspectionName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inspectionId !== undefined && inspectionId !== null) {
            queryParameters = queryParameters.set('inspectionId', <any>inspectionId);
        }
        if (inspectionName !== undefined && inspectionName !== null) {
            queryParameters = queryParameters.set('inspectionName', <any>inspectionName);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionDetailDto>('put',`${this.basePath}/v1/Inspection/UpdateInspectionName`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
